<!--

This Vue component `TaxonomyLevel` is a child component designed to render individual levels
or children within a taxonomy layout. Each item can contain nested children, allowing for an unlimited
depth of taxonomy levels.

It imports the `TaxonomyLevel` component for rendering each sub-level of the taxonomy. The number
of levels is virtually unlimited, as each level can contain nested children with the same structure.

Properties:
- `level`: A number representing the current level in the taxonomy hierarchy.
- `item`: An object containing the data for the current taxonomy item, which can include:
    - `kicker`: A short text displayed above the title.
    - `title`: The main title of the item.
    - `style`: A string representing CSS classes for styling.
    - `colSpan`: Specifies the number of columns the item should span in the grid.
    - `accordion`: An object containing nested children with properties:
        - `title`: The title of the accordion.
        - `copy`: HTML string for the content of the accordion.
        - `taxonomyCta`: An Hyper field.
        - `skeLevelCta`: An Hyper field.
        - `children`: An array of nested children with similar structure.
        - `linkList`: An array of links for additional resources.

The component's props include:
- `level`: A number representing the current level in the taxonomy hierarchy.
- `item`: An object containing the data for the current taxonomy item.
- `activetaxonomy`: An array representing the active taxonomies.
- `levelChanged`: A boolean indicating whether a level has been changed.

The component uses the following functions:
- `toggleAccordion`: Emits a 'changed' event when the accordion is toggled.

# Preload tailwind classes
- lg:col-span-4
- lg:col-span-3
- lg:col-span-2
- lg:col-span-1

-->

<template>
    <section class="taxonomy-section" :class="{
        ['taxonomy-section--' + (item.colSpan ?? '4') + '-cols']: true,
        ['lg:col-span-' + (item.colSpan ?? '4')]: true,
        '--parent': !item.isChild,
        'is-active':
            /* If active taxonomy is included in the current item */
            activetaxonomy.includes(item.slug)

            /* If active taxonomy is included in the children */
            || (item.accordion && item.accordion.children && item.accordion.children.some(child => activetaxonomy.includes(child.slug)))

    }">
        <!-- If not an accordion item, render as a regular section -->
        <!-- Both possible .taxonomy-content elements are styled based on the item.style -->
        <div
            class="taxonomy-content grid gap-1.5 py-5 text-center rounded-[0.25rem]"
            :class="{
                [extractVariableName(item.style)]: item.style,
                '--child': item.isChild
            }" v-if="item.title && item.kicker">
            <small class="block text-sm uppercase" v-if="item.kicker">{{ item.kicker }}</small>
            <h2 class="font-bold text-base" v-if="item.title">{{ item.title }}</h2>
        </div>

        <!-- If doesnt have a kicker, render as compact section, similar to the accordion summary -->
        <div
            class="taxonomy-content text-center rounded-[0.25rem] py-2 px-4"
            :class="{
                [extractVariableName(item.style)]: item.style,
                '--child': item.isChild
            }" v-else-if="item.title && !item.kicker">
            <h2 class="font-bold text-sm uppercase" v-if="item.title">{{ item.title }}</h2>
        </div>

        <!-- If an accordion item, render as a details element -->
        <details
            class="taxonomy-content grid gap-2 rounded-[0.25rem]"
            :class="{
                [extractVariableName(item.style)]: item.style,
                '--child': item.isChild
            }" v-if="item.accordion"
            :open="
                /* Open if active and not level 1 */
                /* (((activetaxonomy.includes(item.slug)) && level) > 1) /* Open if active and not level 1 */

                /* Open if no active taxonomies and startsOpen */
                /* || */ (activetaxonomy.length === 0 && item.startsOpen == true )

                /* Open if level 1 and active taxonomy in children */
                || (level === 1 && item.accordion.children && item.accordion.children.some(child => activetaxonomy.includes(child.slug)))
            "
            :data-taxonomy="item.slug"
        >
            <!-- Summary element with title and accordion icon -->
            <summary :class="{
                '!px-4': level < 3,
                '!px-3 text-center': level >= 3
            }" :data-level="level" @click="toggleAccordion">
                <h3 class="flex gap-1 items-center text-sm" v-if="item.accordion.title">
                    <template v-if="activetaxonomy.includes(item.slug) && level >= 1">
                        <span class="sr-only">(active)</span>
                        <template v-if="level <= 2">
                            <span class="block w-[1em]">
                                <div v-html="Star" class="w-full h-auto"></div>
                            </span>
                            {{ item.accordion.title }}
                        </template>

                        <template v-else>
                            <span class="px-3 py-1.5 rounded-md" :style="{ backgroundColor: parentThemeRef }">
                                {{ item.accordion.title }}
                            </span>
                        </template>
                    </template>

                    <template v-else>
                        {{ item.accordion.title }}
                    </template>
                </h3>

                <div class="taxonomy-icon absolute top-1/2 right-0 w-2.5 h-2.5 transform -translate-y-1/2" aria-hidden="true">
                    <span class="block absolute h-[2px] w-full top-1/2 left-1/2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2"></span>
                    <span class="block absolute h-full w-[2px] top-1/2 left-1/2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2"></span>
                </div>
            </summary>

            <!-- Accordion content with copy, links, and nested children -->
            <div :class="{
                '--has-no-parent-child': checkForParentInChild(item.accordion.children),
            }">
                <div class="pb-1">
                    <div class="taxonomy-copy copy richtext richtext--xs">
                        <div v-if="item.accordion.topLink">
                            <a class="underline" :href="item.accordion.topLink.url">{{ item.accordion.topLink.label }}</a>
                        </div>

                        <div v-if="item.accordion.copy" v-html="item.accordion.copy"></div>

                        <div class="flex justify-center" v-if="item.accordion.taxonomyCta">
                            <a class="inline-flex items-center gap-2" :href="item.accordion.taxonomyCta.url" :target="item.accordion.taxonomyCta.target ?? null">
                                <span class="underline">{{ item.accordion.taxonomyCta.text }}</span>
                                <div v-html="ArrowRight" class="w-1"></div>
                            </a>
                        </div>
                    </div>

                    <template v-if="item.accordion.relatedSkillExplainerEntries && item.accordion.relatedSkillExplainerEntries.length">

                        <section class="border-t"
                            v-for="child in item.accordion.relatedSkillExplainerEntries"
                            :key="child.id"
                            :style="{ 'border-color': parentThemeRef }"
                        >

                            <details class="text-left !p-0">
                                <summary class="relative !justify-start underline !font-medium !pr-4">
                                    {{ child.title }}

                                    <div class="taxonomy-icon absolute top-1/2 right-0 w-2.5 h-2.5 transform -translate-y-1/2" aria-hidden="true">
                                        <span class="block absolute h-[2px] w-full top-1/2 left-1/2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2"></span>
                                        <span class="block absolute h-full w-[2px] top-1/2 left-1/2 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2"></span>
                                    </div>
                                </summary>

                                <div class="mb-4 mt-2 no-print" v-if="item.skeLevelCta">
                                    <a class="inline-flex gap-1.5 items-center text-xs underline" :href="child.url">
                                        Learn More <span class="sr-only">about {{ child.title }}</span>
                                        <div v-html="ArrowRight" class="w-1"></div>
                                    </a>
                                </div>

                                <div class="text-xs my-2" v-if="child.skeLevelCta">
                                    <a class="inline-flex items-center gap-2" :href="child.skeLevelCta.url" :target="child.skeLevelCta.target ?? null">
                                        <span class="underline">{{ child.skeLevelCta.text }}</span>
                                        <div v-html="ArrowRight" class="w-1"></div>
                                    </a>
                                </div>

                                <div class="richtext richtext--xs mb-4">
                                    <div class="mb-3" v-if="child.copy" v-html="child.copy"></div>
                                    <ul class="grid !ml-3.5" v-if="child.modules">
                                        <li v-for="link in child.modules" :key="link.id">
                                            <a class="underline" :href="link.url">{{ link.label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </details>
                        </section>
                    </template>
                </div>

                <!-- Recursively render nested children with the same component -->
                <div class="taxonomy-subItems grid gap-1 lg:grid-cols-4 items-start -mx-4 p-1" v-if="item.accordion.children && item.accordion.children.length">
                    <TaxonomyLevel
                        :item="subItem"
                        :level="level + 1"
                        :activetaxonomy="activetaxonomy"
                        :levelChanged="levelChanged"
                        :parenttheme="parentThemeRef"
                        v-for="subItem in item.accordion.children"
                        :key="subItem.id"
                        @changed="toggleAccordion" />
                </div>
            </div>
        </details>
    </section>
</template>

<script setup>
    import { ref } from 'vue';
    import Star from '/src/svgs/Star.svg?raw';
    import TaxonomyLevel from './TaxonomyLevel.vue';
    import ArrowRight from '/src/svgs/ArrowRight.svg?raw';

    const parentThemeRef = ref(null);

    // example: var(--color-primary) -> --color-primary
    const extractVariableName = (slug) => {
        if (!slug) return null;

        const variableName = slug.match(/^var\((--[\w-]+)\)$/);
        return variableName ? variableName[1] : null;
    }

    const checkForParentInChild = (children) => {
        if (!children) return false;

        return children.some(child => child.isChild);
    }

    const emit = defineEmits(['changed']);
    const props = defineProps({
        level: Number,
        item: Object,
        activetaxonomy: Array,
        levelChanged: Boolean,
        parenttheme: String
    });

    const toggleAccordion = () => {
        emit('changed', true);
    };

    // If level 1, set a reference to CSS variable the parent theme
    if (props.level === 1) {
        parentThemeRef.value = props.item.style;
    } else {
        parentThemeRef.value = props.parenttheme ?? null;
    }

    document.documentElement.style.setProperty('--parentColor', parentThemeRef.value);

    //console.log(props.item);

</script>

<style scoped>

</style>
