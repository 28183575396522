<template>
    <!-- Card Heading -->
    <div :class="classnames?.header">
        <!--
            Display the category and locator tags if they exist.
            This might need to be adjusted depending on the nature of the content,
            since the data handles might be different. We can probably use a computed
            property to determine which tags to display based on the content type.
        -->

        <!-- Specific element for QA cards -->
        <template v-if="handle == 'qa'">
            <!-- Category -->
            <div class="inline-flex px-4 py-1.5 mb-6 uppercase font-bold text-xs rounded-lg bg-orange-2">
                {{ card.locator.title }}
            </div>
        </template>

        <template v-else>
            <!-- Locator -->
            <a
                v-if="card.locator"
                :href="card.locator.url"
                :style="`background-color: ${backgroundColor};`"
                :class="`${labelTextColor} -mx-6 -mt-6 mb-6 px-4 py-1.5 uppercase font-bold text-xs rounded-t-lg block`"
            >
                {{ card.locator.title }}
            </a>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            handle: {
                type: String,
                required: false
            },
            card: {
                type: Object,
                required: true
            },
            classnames: {
                type: [Array, Object],
                default: () => []
            },
            backgroundColor: {
                type: String,
                require: false,
                default: ""
            },
            labelTextColor: {
                type: String,
                require: false,
                default: ""
            }
        },
        data() {
            return {}
        }
    }
</script>
