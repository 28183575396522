
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

const tips = {
    init() {
        const $tips = document.querySelectorAll('.js-tip');

        $tips.forEach((tip) => {
            // data-tip-content
            // allowHTML interactive maxWidth="200px"
            this.element(tip);
        });
    },
    element(tip) {

        if (!tip) {
            return;
        }

        const content = tip.dataset.tipContent;
        //console.log('content',content)

        tippy(tip, {
            trigger: 'click',
            arrow: true,
            theme: 'blue',
            content: content,
            allowHTML: true,
            interactive: true,
            maxWidth: '17.5rem'
        });

        // append close button to tooltip
        const tooltip = tip._tippy;
        const closeButton = document.createElement('button');
        closeButton.innerHTML = [
            '<span class="sr-only">Close</span>',
            '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.23494 2.39454C3.04224 2.20184 2.72981 2.20184 2.5371 2.39454C2.3444 2.58724 2.3444 2.89967 2.5371 3.09237L5.44474 6.00001L2.5371 8.90766C2.3444 9.10036 2.3444 9.41279 2.5371 9.60549C2.72981 9.79819 3.04224 9.79819 3.23494 9.60549L6.14258 6.69785L9.05022 9.60549C9.24292 9.79819 9.55535 9.79819 9.74805 9.60549C9.94075 9.41279 9.94075 9.10036 9.74805 8.90766L6.84041 6.00002L9.74805 3.09237C9.94075 2.89967 9.94075 2.58724 9.74805 2.39454C9.55535 2.20184 9.24292 2.20184 9.05022 2.39454L6.14258 5.30218L3.23494 2.39454Z" fill="currentColor"></path></svg>',
        ].join('');
        closeButton.classList.add('tippy-close');
        closeButton.addEventListener('click', () => {
            tooltip.hide();
        });
        tooltip.popper.querySelector('.tippy-content').appendChild(closeButton);

    }
};

export default tips;
