<template>
    <!--
        We're passing the filters as props to the FiltersTypeB component.

        FiltersTypeB is used for Essential Support module exclusively.

        There are three filters:
        - Filter by Topic: Uses the "topics" field options.
        - Filter by Resource Type: Uses the "resourcetype" field options.

        Both are passed to the CardsGrid Vue component by the respective
        Twig module partial templates/_partials/_modules/essentialSupportSearch.twig

        The Sort by filter is hardcoded here, nothing is functional yet.
    -->
    <FiltersTypeB :filters="[
        {
            title: 'Filter by Topic',
            handle: 'topics',
            items: topics,
        },
        {
            title: 'Filter by Resource Type',
            handle: 'resourceType',
            items: resourcetype,
        },
        {
            title: 'Sort by',
            handle: 'sort',
            items: [
                {
                    title: 'Sort by Newest',
                    slug: 'newest',
                },
                {
                    title: 'Sort by Relevance',
                    slug: 'relevance',
                },
                {
                    title: 'Sort by Popularity',
                    slug: 'popularity',
                },
                {
                    title: 'Sort Alphabetically',
                    slug: 'alphabetically',
                }
            ],
        }
   ]" @updateActiveFilters="handleUpdateActiveFilters" />
</template>

<script setup>
    import FiltersTypeB from './FiltersTypeB.vue';

    /* Define the emit function for the updateActiveFilters event */
    const emit = defineEmits(['updateActiveFilters']);
    const handleUpdateActiveFilters = (updatedFilters) => {
        emit('updateActiveFilters', updatedFilters);
    };

    const props = defineProps({
        topics: {
            type: Array,
            default: () => []
        },
        resourcetype: {
            type: Array,
            default: () => []
        }
    });
</script>
