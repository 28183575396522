import { reactive } from 'vue'

let userIdContainer = document.querySelector('#user-id-container');
let userId = userIdContainer ? userIdContainer.dataset.userId : null;
let userName = userIdContainer ? userIdContainer.dataset.userName : null;
let loginUrl = userIdContainer ? userIdContainer.dataset.loginUrl : null;
let searchContainer = document.querySelector('#global-search-params');
let searchParams = searchContainer ? searchContainer.dataset.params : null;

export const store = reactive({
  userId: userId,
  userName: userName,
  favorites: [],
  search: searchParams,
  updateFavorites(idArray) {
    this.favorites = idArray;
  },
  updateUserId(id) {
    this.userId = id;
    return this.userId;
  },
  updateUserName(name) {
    this.userName = name;
    return this.userName;
  },
  updateLoginUrl(url) {
    this.loginUrl = url;
    return this.loginUrl;
  },
  updateParams(params) {
    this.search = params;
    return this.search;
  }
});