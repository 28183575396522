<template>
    <div class="glossaryWrapper-popup" :style="'top:' + targetTop + 'px'">
        <button class="close" @click="closePopup">
            <span class="block w-5">
                <div v-html="Close" class="w-full h-auto"></div>
            </span>
            <span class="sr-only">Close</span>
        </button>
        <h3 class="headline-04">{{ title }}</h3>
        <span aria-hidden="true" class="arrow" :style="'left:' + targetLeft +'px'"></span>
        <div v-html="copy"></div>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue';
    import ToolTipIcon from '/src/svgs/Info.svg?raw';
    import Modal from './Modal.vue';
    import Close from '/src/svgs/Close.svg?raw';

    const modalOpen = ref(false);
    const emit = defineEmits(['close']);

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        copy: {
            type: String,
            required: true
        },
        targetTop: {
            type: Number,
            required: true
        },
        targetLeft: {
            type: Number,
            required: true
        }
    });

    const closePopup = () => {
        emit('close');
    };
</script>

