<template>
    <nav class="grid gap-4">
        <!-- Loop through the filters groups -->
        <section class="w-full grid gap-4" v-for="filter in filters" :key="filter.id">
            <!-- Some filters are collapsable (Show More/Show Less) -->
            <div class="w-full" :class="{
                'is-collapsable': filter.collapsable,
                'is-active': !collpasedFilters[filter.groupSlug]
            }">
                <!-- Filter title (some have the key "title" and others "label") -->
                <strong>{{ filter.title || filter.label }}</strong>
                <ul class="mt-4 flex flex-wrap gap-4">
                    <li>
                        <!-- Button to remove all active filters for this group -->
                        <button
                            class="button button--small button--outline button--rounded"
                            :class="{ 'is-active': filter?.items && !activeFilters.some(activeFilter => activeFilter.groupSlug === filter.groupSlug) }"
                            @click="removeActiveFilters(filter.groupSlug)"
                        >All</button>
                    </li>

                    <!-- Loop through the filter items. For filters of type A (Blog Filters), the user can select multiple items -->
                    <template v-if="filter?.items">
                        <!-- Check if tag -->
                        <template v-for="item in filter.items" :key="item.id">
                            <li v-if="item.value">
                                <button
                                    class="button button--small button--outline button--rounded"
                                    :class="{ 'is-active': activeFilters.some(activeFilter => activeFilter.title === item.title) }"
                                    @click="toggleFilter(filter, item)"
                                >{{ item.title || item.label }}</button>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>

            <!-- Button to show more/less items for collapsable filters -->
            <button class="button button--small button--black button--transparent button--uppercase button--gray-12" v-if="filter.collapsable" @click="toggleCollapse(filter.groupSlug)">
                <span class="inline-flex gap-1 items-center" v-if="collpasedFilters[filter.groupSlug]">
                    <span class="block w-2.5">
                        <div v-html="PlusThick" class="w-full h-auto"></div>
                    </span>
                    View More
                </span>
                <span class="inline-flex gap-1 items-center" v-else>
                    <span class="block w-2.5">
                        <div v-html="MinusThick" class="w-full h-auto"></div>
                    </span>
                    View Less
                </span>
            </button>
        </section>
    </nav>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import PlusThick from '/src/svgs/PlusThick.svg?raw';
    import MinusThick from '/src/svgs/MinusThick.svg?raw';

    /* Define the props and emits */
    const activeFilters = ref([]);
    const props = defineProps({
        filters: {
            type: Array,
            default: () => []
        }
    });

    const emit = defineEmits(['updateActiveFilters']);

    const collpasedFilters = ref({});

    /* Function to toggle the collapse state of a filter */
    const toggleCollapse = (groupSlug) => {
        collpasedFilters.value[groupSlug] = !collpasedFilters.value[groupSlug];
    };

    /* Function to toggle the active state of a filter item */
    const toggleFilter = (category, item) => {
        item.active = !item.active;
        //console.log(`Category ${category.handle} item ${item.title} active state: ${item.active}`);

        if (item.active) {
            activeFilters.value.push(item);
        } else {
            activeFilters.value = activeFilters.value.filter(activeFilter => activeFilter.title !== item.title);
        }

        /* Emit the active filters to the parent component */
        emit('updateActiveFilters', activeFilters.value);
    };

    /* Function to remove all active filters for a group */
    const removeActiveFilters = (handle) => {
        activeFilters.value = activeFilters.value.filter(activeFilter => activeFilter.groupSlug !== handle);

        const filter = props.filters.find(filter => filter.groupSlug === handle);

        if (filter?.items) {
            filter.items.forEach(item => {
                item.active = false;
            });
        }

        /* Emit the active filters to the parent component */
        emit('updateActiveFilters', activeFilters.value);
    };

    onMounted(() => {
        /* Debugging */
        //console.log('props.filters', props.filters);

        // Check collapsable filters
        props.filters.forEach(filter => {
            if (filter.collapsable) {
                collpasedFilters.value[filter.groupSlug] = true;
            }
        });
    });

</script>

<style scoped>
    .is-collapsable {
        display: none;
    }

    .is-collapsable.is-active {
        display: block;
    }
</style>
