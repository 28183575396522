let youtubeVideosQueue = [];
let activePlayers = [];
let playerVars = {};

const defaultPlayerVars = {
    'modestbranding': 1,
    'rel': 0,
    'playsinline': 1,
    'mute': 1
};

export function VideoPlayers() {
    document.addEventListener('DOMContentLoaded', () => {
        enhanceYouTubeEmbeds();
        initializeVideoPlayers();
        loadYouTubeAPI();
    });
}

function initializeVideoPlayers() {
    const videoContainers = document.querySelectorAll('.js-video');

    if (videoContainers.length === 0) return;

    videoContainers.forEach(container => {
        const videoThumbnail = container.querySelector('.js-video-thumbnail');
        const videoPlayer = container.querySelector('.js-video-player');

        playerVars = defaultPlayerVars;

        // if videoContainer has .--has-autoplay class, play video automatically
        // when on viewport
        if (container.classList.contains('--has-autoplay')) {
            setTimeout(() => { // Wait for oEmbed dependencies to load
                autoplayVideo(container, videoThumbnail, videoPlayer);
            }, 1000);
        }

        if (!videoThumbnail || !videoPlayer) return;

        videoThumbnail.addEventListener('click', () => playVideo(videoThumbnail, videoPlayer, false));
        videoThumbnail.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                playVideo(videoThumbnail, videoPlayer, false);
            }
        });
    });
}

function autoplayVideo(container, videoThumbnail, videoPlayer) {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                playerVars = {
                    'autoplay': 1,
                    'controls': 1,
                    'modestbranding': 1,
                    'rel': 0,
                    'showinfo': 0,
                    'loop': 1,
                    'mute': 1,
                    'playsinline': 1
                };

                playVideo(videoThumbnail, videoPlayer, true);
                observer.disconnect();
            }
        });
    }, {
        threshold: 0.1 // 10% of the video is visible
    });

    observer.observe(container);
}

function playVideo(thumbnail, player, autoplay) {
    thumbnail.classList.add('is-loading');

    pauseAllVideos();
    if (player.src.includes('youtube.com')) {
        playYouTubeVideo(thumbnail, player, autoplay);
    } else {
        playHTMLVideo(thumbnail, player);
    }
}

function pauseAllVideos() {
    const videoPlayers = document.querySelectorAll('.js-video-player');

    videoPlayers.forEach(player => {
        if (player.tagName.toLowerCase() === 'iframe') {
            // YouTube video
            if (player.src.includes('youtube.com')) {
                player.contentWindow.postMessage(JSON.stringify({
                    event: 'command',
                    func: 'pauseVideo'
                }), '*');
            }
        } else if (player instanceof HTMLVideoElement) {
            // HTML5 video
            if (typeof player.pause === 'function') {
                player.pause();
            }
        }
    });
}

function playYouTubeVideo(thumbnail, player, autoplay = false) {
    if (!player || !player.contentWindow) {
        console.error('Invalid player element');
        return;
    }

    // Function to post message to iframe
    const postMessageToPlayer = (action) => {
        player.contentWindow.postMessage(JSON.stringify({
            event: 'command',
            func: action
        }), '*');
    };

    // Play the video
    postMessageToPlayer('playVideo');

    // If autoplay is false, unmute the video
    if (!autoplay) {
        postMessageToPlayer('unMute');
        postMessageToPlayer('setVolume', [100]);
    }

    // Hide thumbnail and show video
    if (thumbnail) {
        thumbnail.style.display = 'none';
    }

    player.classList.add('!opacity-100');
}

function loadYouTubeAPI() {
    if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
    }
}

function playHTMLVideo(thumbnail, player) {
    if (typeof player.play === 'function') {
        activePlayers.push(player);
        player.play().catch(error => {
            console.warn('Error playing video:', error);
        });

        player.addEventListener('playing', () => {
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
        });
    } else {
        console.warn('Video player does not support HTML5 video API.');
    }
}

function enhanceYouTubeEmbeds() {
    // Find all elements with js-richtext-video class
    const videoContainers = document.querySelectorAll('.richtext figure iframe');

    videoContainers.forEach(container => {
        const figure = container.closest('figure');
        if (figure) {
            const iframe = figure.querySelector('iframe');
            if (iframe && iframe.src && iframe.src.includes('youtube.com/embed/')) {
                // Add js-video-player class to the iframe
                iframe.classList.add('js-video-player');

                // Extract video ID
                const videoId = iframe.src.split('/').pop().split('?')[0];

                // Create thumbnail element
                const thumbnailElement = document.createElement('div');
                thumbnailElement.className = 'video-thumbnail js-video-thumbnail';
                thumbnailElement.tabIndex = 0;
                thumbnailElement.setAttribute('role', 'button');
                thumbnailElement.setAttribute('aria-label', 'Play video');
                thumbnailElement.innerHTML = `
                    <img src="https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg" alt="Video thumbnail">
                    <div class="video-play">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 70 71" fill="none">
                        <circle cx="35" cy="35.4839" r="35" fill="#F18A0F"></circle>
                        <path d="M48.6432 34.6359C49.2699 35.0276 49.2699 35.9402 48.6432 36.3319L27.53 49.5276C26.8639 49.9439 26 49.4651 26 48.6796L26 22.2881C26 21.5027 26.864 21.0239 27.53 21.4401L48.6432 34.6359Z" fill="white"></path>
                    </svg>
                    </div>
                `;

                // Create a new div to wrap everything
                const wrapperDiv = document.createElement('div');
                wrapperDiv.className = 'video js-video [&>.video-wrapper>iframe]:opacity-0';

                // Create video-wrapper div
                const videoWrapper = document.createElement('div');
                videoWrapper.className = 'video-wrapper';

                // Move the iframe into the video-wrapper
                videoWrapper.appendChild(iframe);

                // Add the thumbnail to the video-wrapper
                videoWrapper.appendChild(thumbnailElement);

                // Add the video-wrapper to the main wrapper
                wrapperDiv.appendChild(videoWrapper);

                // Replace the original figure with the new structure
                figure.parentNode.replaceChild(wrapperDiv, figure);
            }
        }
    });
}
