<template>
    <!--
        We're passing the filters as props to the FiltersTypeA component.

        FiltersTypeA is used for Blog Posts and All Blog Posts module exclusively.

        There are three filters:
        - Filter by Type: Uses the "blog" categories group entries.
        - Filter by Essential Support Tags: Uses the "essentialsupport" field options.

        Both are passed to the CardsGrid Vue component by the respective
        Twig module partials templates/_partials/_modules/blogPosts.twig and
        templates/_partials/_modules/allBlogPosts.twig
    -->
    <FiltersTypeA :filters="[
        {
            title: 'Browse by Type',
            handle: 'type',
            items: categories,
            collapsable: false,
            groupSlug: 'blog',
        },
        {
            title: 'Browse by Essential Support Tags',
            handle: 'tags',
            items: essentialsupport,
            collapsable: true,
            collapsed: true,
            groupSlug: 'essentialSupportField',
        }
    ]" @updateActiveFilters="handleUpdateActiveFilters" />
</template>

<script setup>
    import FiltersTypeA from './FiltersTypeA.vue';

    /* Define the emit function for the updateActiveFilters event */
    const emit = defineEmits(['updateActiveFilters']);

    const handleUpdateActiveFilters = (updatedFilters) => {
        emit('updateActiveFilters', updatedFilters);
    };


    const props = defineProps({
        categories: {
            type: Array,
            default: () => []
        },
        essentialsupport: {
            type: Array,
            default: () => []
        }
    });
</script>
