<template>

    <div :id="handle">
        <!--
            If this is a 'search' module and the showtaxonomy is enabled, show the taxonomy component.
            This is used on the Module Twenty-Seven: Search Module
        -->
        <Taxonomy v-if="handle == 'search' && showtaxonomy" :hideable="true" :activetaxonomy="activetaxonomy">
        </Taxonomy>

        <!--
            If this is a 'allBlogPosts' module and the showfilters is enabled, show the blog filters component.
            This is used by the Module Twenty-Two: All Blog Posts
        -->
        <BlogFilters v-if="handle == 'allBlogPosts' && showfilters && blogcategories"
            :categories="blogcategories"
            :essentialsupport="essentialsupport"
            @updateActiveFilters="handleUpdateActiveFilters"
        />

        <!-- Enable this to debug the active filters -->
        <!-- <pre>{{ activeFilters }}</pre> -->

        <!--
            If this is a 'search' module and the showfilters is enabled, show the search filters component.
            This is used by the Module Twenty-Seven: Search Module AND Module Twenty-Four: Essential Support Module

            I used the 'search' handle to both modules because I assumed that they shared the same filters, but if each
            filter correspond to a different field/category, this may need to be changed.
        -->
        <SearchFilters v-if="handle == 'search' && showfilters"
            :topics="topics"
            :resourcetype="resourcetype"
            @updateActiveFilters="handleUpdateActiveFilters"
        >
        </SearchFilters>

        <!--
            If this is a 'qa' module and the showfilters is enabled, show the qa filters component.
            This wasn't implemented yet, but should be used by Module Thirty-One: Top 100 Q&As.
        -->
        <QaFilters v-if="handle == 'qa' && showfilters && topics"
            :topics="topics"
            :essentialsupport="essentialsupport"
            @updateActiveFilters="handleUpdateActiveFilters"
        />

        <ul
            class="pt-12"
            :class="{
                [classnames.root]: true,
                'is-loading': isLoading
            }"
            v-if="cardsRef && cardsRef.length > 0"
        >
            <li class="h-full flex justify-stretch items-stretch" v-for="card in cardsRef" :key="card.id">
                <Card :handle="handle" :card="card" :currentuser="currentuser" :classnames="classnames?.item" color="var(--orange-2)" />
            </li>
        </ul>

        <Pagination
            v-if="paginationData.totalPages > 1 && renderPagination"
            :current-page="paginationData.page"
            :total="paginationData.total"
            :count="paginationData.count"
            :per-page="paginationData.perPage"
            :total-pages="paginationData.totalPages"
            :previous-link="paginationData.previousLink"
            :next-link="paginationData.nextLink"
            @paginate="paginate"
        />
    </div>
</template>

<script setup>
    import { ref, onMounted, nextTick } from 'vue';
    import axios from 'axios';
    import Taxonomy from './Taxonomy.vue';
    import QaFilters from './QaFilters.vue';
    import BlogFilters from './BlogFilters.vue';
    import SearchFilters from './SearchFilters.vue';
    import Card from './cards/Card.vue';
    import Pagination from './pieces/Pagination.vue';

    const paginationData = ref({
        page: 1,
        total: 0,
        count: 0,
        perPage: 6,
        totalPages: 0,
        previousLink: null,
        nextLink: null
    });

    // Flag to render the pagination component
    const renderPagination = ref(true);
    const forceRenderPagination = async () => {
        renderPagination.value = false;
        // Wait for the change to get flushed to the DOM
        await nextTick();
        renderPagination.value = true;
    };

    // Function to handle the pagination
    const paginate = (page) => {
        // Update the page number
        paginationData.value.page = page;

        // Fetch the data
        fetchCards();

        // scroll to the top of the cards grid
        const cardsGrid = document.getElementById(props.handle);
        cardsGrid.scrollIntoView({ behavior: 'smooth' });
    };

    // Function to update the pagination data when the API returns that data
    const updatePagination = (meta) => {
        const pagination = meta.pagination;
        paginationData.value = {
            page: pagination.current_page,
            total: pagination.total,
            count: pagination.count,
            perPage: pagination.per_page,
            totalPages: pagination.total_pages,
            nextLink: pagination.links.next,
        };

        // Update the URL with the new page number
        const url = new URL(window.location.href);
        url.searchParams.set('page', pagination.current_page);
        window.history.pushState({}, '', url);

        // Force the pagination component to re-render
        forceRenderPagination();
    };

    const props = defineProps({
        handle: {
            type: String,
        },
        showtaxonomy: {
            type: Boolean,
            default: false
        },
        activetaxonomy: {
            type: String,
            default: null
        },
        showfilters: {
            type: Boolean,
            default: false
        },
        blogcategories: {
            type: Array,
            default: () => []
        },
        essentialsupport: {
            type: Array,
            default: () => []
        },
        topics: {
            type: Array,
            default: () => []
        },
        resourcetype: {
            type: Array,
            default: () => []
        },
        cards: {
            type: Array,
            default: () => []
        },
        currentuser: {
            type: Object,
            default: () => {}
        },
        classnames: {
            type: [Array, Object],
            default: () => []
        }
    });

    /*
        Current active filters that are passed to the API calls.

        Right now, we have API calls for blog (Blog Posts and All Blog Posts modules)
        and articles (Essential Support module), and both support filters.

        This variable is updated by the child components (filters) emitting an event,
        when the user selects a filter.
    */
    const activeFilters = ref([]);

    /*
        Used to toggle a is-loading class on the root element, to reduce opacity
        and remove pointer-events while waiting for the API response.
    */
    const isLoading = ref(false);

    /*
        Reference to the cards array, which is updated by the API calls.
        Contains the cards to be rendered on the grid.
    */
    const cardsRef = ref(props.cards);

    /*
        Function to fetch the blog entries from the API.
        This is used by the Blog Posts and All Blog Posts modules.
    */
    const getBlogEntries = async () => {
        isLoading.value = true;
        const response = await axios.get('/api/blog-entries.json', {
            params: {
                filters: activeFilters.value,
                page: paginationData.value.page
            }
        }).catch((error) => {
            console.error('Error fetching blog posts:', error);
        }).finally(() => {
            isLoading.value = false;
        });

        return response.data;
    }

    /*
        Function to fetch the articles entries from the API.
        This is used by the Essential Support module.
    */
    const getArticlesEntries = async () => {
        isLoading.value = true;
        const response = await axios.get('/api/articles-entries.json', {
            params: {
                filters: activeFilters.value,
                page: paginationData.value.page
            }
        }).catch((error) => {
            console.error('Error fetching articles posts:', error);
        }).finally(() => {
            isLoading.value = false;
        });

        return response.data;
    }

    /*
        Function to fetch the qa entries from the API.
        This is used by the Top 100 Q&As module.
    */
    const getQaEntries = async () => {
        isLoading.value = true;
        const response = await axios.get('/api/qa-entries.json', {
            params: {
                filters: activeFilters.value,
                page: paginationData.value.page
            }
        }).catch((error) => {
            console.error('Error fetching qa posts:', error);
        }).finally(() => {
            isLoading.value = false;
        });

        return response.data;
    }

    /*
        Function to handle the update of the active filters.

        This function is called by the child components (filters) when the user
        selects a filter.
    */
    const handleUpdateActiveFilters = async (updatedFilters) => {
        //console.log('Updated filters from child:', updatedFilters);

        // Reset pagination
        paginationData.value.page = 1;

        // Update the active filters
        activeFilters.value = updatedFilters;

        // Fetch the data
        await fetchCards();
    };

    const fetchCards = async () => {
        if (props.handle === 'allBlogPosts') {
            const blogPosts = await getBlogEntries();
            cardsRef.value = blogPosts.data;
            updatePagination(blogPosts.meta);
        }

        if (props.handle === 'search') {
            const articlesPosts = await getArticlesEntries();
            cardsRef.value = articlesPosts.data;
            updatePagination(articlesPosts.meta);
        }

        if (props.handle === 'qa') {
            const qaPosts = await getQaEntries();
            cardsRef.value = qaPosts.data;
            updatePagination(qaPosts.meta);
        }
    }

    /*
        On mounted, fetch the blog entries from the API.
        This is used by the Blog Posts and All Blog Posts modules.

        Looking at this now, both this logic and the logic above could have been
        unified into a single function, but I wanted to keep them separated for clarity
        now that we are only using parts of this code.
    */
    onMounted(async () => {
        // Get the page number from the URL, if present
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // Get filter values, if present
        // Get page number, if present
        const pageNumber = urlParams.get('page');

        // Initialize
        paginationData.value.page = pageNumber ? parseInt(pageNumber) : 1;

        // Initialize the active filters and fetch the data
        await fetchCards();
    });
</script>
