import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';
import axios from 'axios';

const newsletterHandler = {
    init() {
        // Initialize newsletter form javascript
        let $form = document.querySelector('#newsletter-signup-form');
        if ($form && $form.length > 0) {
            let FormieInstance = new Formie();
            FormieInstance.initForm($form);
        }
    }
};

export default newsletterHandler;