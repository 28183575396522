<template>
    <FiltersTypeA :filters="[
        {
            title: 'Browse by Topic',
            handle: 'type',
            items: topics,
            collapsable: false,
            groupSlug: 'topics',
        },
        {
            title: 'Browse by Essential Support Tags',
            handle: 'tags',
            items: essentialsupport,
            collapsable: true,
            collapsed: true,
            groupSlug: 'essentialSupportField',
        }
    ]" @updateActiveFilters="handleUpdateActiveFilters" />
</template>

<script setup>
    import FiltersTypeA from './FiltersTypeA.vue';

    /* Define the emit function for the updateActiveFilters event */
    const emit = defineEmits(['updateActiveFilters']);

    const handleUpdateActiveFilters = (updatedFilters) => {
        emit('updateActiveFilters', updatedFilters);
    };


    const props = defineProps({
        topics: {
            type: Array,
            default: () => []
        },
        essentialsupport: {
            type: Array,
            default: () => []
        }
    });
</script>
