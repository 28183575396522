<template>
    <ul class="flex flex-wrap gap-2" v-if="tags && tags.length > 0">
        <li
            v-for="(tag, index) in tags"
            :class="'flex-initial px-1 py-1 inline-flex items-center justify-start gap-2 ' + classes"
            :key="index"
        >
            <small v-if="tag && typeof tag === 'object'" class="tag">{{ tag.label ? tag.label : tag.title }}</small>
            <small v-else class="tag">{{ tag }}</small>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            tags: {
                type: Object,
                require: true
            },
            classes: {
                type: String,
                require: false,
                default: ''
            }
        },
        data() {
            return {}
        }
    }
</script>
